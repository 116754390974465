import { Provider } from "react-redux";
import store from "./redux/store";
import { AppRoutes } from './helper/router-helper';
import { useUserState } from './redux/hooks/authenticationHooks';
import { Provider as ThemeProvider } from '@fluentui/react-northstar';
import ThemeHelper from './helper/theme-helper';
import { useTheme } from './redux/hooks/useTheme';
import { useLoader } from './redux/hooks/useLoader';
import { TeamsLoader } from './Component/common/loader-northstar'
import { useEffect, useState } from "react";
// import ThemeSelectorSplitButton from "./Component/common/theme.Changer";
import TelemetryProvider from "./Component/Telemetry/telemetry-provider";
import { BrowserRouter } from "react-router-dom";
// import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
// import { ErrorAlert } from "./Component/Error/errorAlert";
// import { reactPlugin } from "./Services/TelemetryService"
import './App.css'
import { Utilities } from "./helper/Utility";
import { app } from "@microsoft/teams-js";
import { isBrowser, isMobile } from "react-device-detect";
import { routes as routeDefinitions } from './config';
import { Unauthorized } from "./Component/Pages/Unauthorized/Unauthorized";
import { Login } from './Component/auth/login';

export const TeamsApp: React.FC<React.PropsWithChildren<{}>> = props => {
    return (
        <BrowserRouter>
            <TelemetryProvider>
                <Provider store={store}>
                    <FeedbackTeamsApp />
                </Provider>
            </TelemetryProvider>
        </BrowserRouter>
    );
}

export const FeedbackTeamsApp: React.FC<React.PropsWithChildren<{}>> = props => {
    const [theme, setTheme] = useTheme();
    const [user] = useUserState();
    const [loader] = useLoader();
    const [isRouteFlag, setRouteFlag] = useState<boolean>(false);
    const currentTheme = ThemeHelper.getTheme(theme.themeName);
    let [backColor, setbackColor] = useState('#8080801a'); //eslint-disable-line @typescript-eslint/no-unused-vars

    const getClassNameByTheme = () => {
        switch (theme.themeName) {
            case "default":
                return '#8080801a';
            case "dark":
                return "#8080801a";
            case "contrast":
                return "#2d2c2c";
            default:
                return "#8080801a";
        }
    };

    const GetTeamsContext = async () => {
        if(isBrowser){
            if ((window !== window.parent) && (window !== window.top)) {
                await app.initialize().then(() => {
                    app.getContext().then((context: app.Context) => {
                        setTheme(context.app!.theme!);
                        app.registerOnThemeChangeHandler((themeName) => {
                            Utilities.LogInfo('registerOnThemeChangeHandler', "TeamsApp.tsx -> FeedbackTeamsApp");
                            setTheme(themeName);
                        });
                        setbackColor(getClassNameByTheme());
                    });
                });
            }
        }
        
        if(isMobile){
            try{
                await app.initialize().then(() => {
                    app.getContext().then((context: app.Context) => {
                        setTheme(context.app!.theme!);    
                        app.registerOnThemeChangeHandler((themeName) => {
                            Utilities.LogInfo('registerOnThemeChangeHandler', "TeamsApp.tsx -> FeedbackTeamsApp");
                            setTheme(themeName);
                        });
                        setbackColor(getClassNameByTheme());
                    });
                });
            }
            catch(err){}
        }
    };

    const isValidRoute = (): boolean => {
        let requestedRoute = window.location.pathname.toLowerCase();
        //will only update the state for teams application
        if(((window !== window.parent) && (window !== window.top))){
        setRouteFlag(routeDefinitions.some(route => route.route.toLowerCase() === requestedRoute))
        }
        return routeDefinitions.some(route => route.route.toLowerCase() === requestedRoute);
    };

    useEffect(() => {
        GetTeamsContext();
    }, []) //eslint-disable-line react-hooks/exhaustive-deps

    //Logic for browser view
    if (((window === window.parent) && (window === window.top))) {
        return (
            <ThemeProvider theme={currentTheme}>
                {loader.isLoading && <TeamsLoader label={loader.loaderMessage} />}
                {isValidRoute() ? (
                    <AppRoutes isLoggedIn={user.currentUser.isAuthenticated} />
                ) : (
                    window.location.href = "/"
                )}
            </ThemeProvider>
        );
    }
    //Logic For Teams Application
    else{
        //code will execute only if user and route is valid
        if (user.currentUser.isAuthenticated && isRouteFlag) {
            return (
                <ThemeProvider theme={currentTheme}>
                    {loader.isLoading && <TeamsLoader label={loader.loaderMessage} />}
                        <AppRoutes isLoggedIn={user.currentUser.isAuthenticated} />
                </ThemeProvider>
            );
        }
        //will execute when user is valid but route is not valid.
        // will store the current window location in localstorage and again validate the route
        else if (user.currentUser.isAuthenticated && !isRouteFlag) {
            localStorage.setItem("origin", window.location.href);
            return (
                <ThemeProvider theme={currentTheme}>
                    {loader.isLoading && <TeamsLoader label={loader.loaderMessage} />}
                    {isValidRoute() ? (
                        <AppRoutes isLoggedIn={user.currentUser.isAuthenticated} />
                    ) : (
                        window.location.href = "/"
                    )}
                </ThemeProvider>
            );
        }
        else {
            //First time login into the application
            return <Login />
        }
        }
}