import { Constants } from "../helper/constants";
import { loginRequest, msalInstance } from "../helper/AuthContext";
import { Utilities } from "./Utility";
import { app, authentication } from "@microsoft/teams-js";
import { logCustomError } from "../Services/TelemetryService";

export default class AuthHelper {
  /**
   * Uses the current authetication context to check if a user
   * is logged in. In this case, this is determined by the presence
   * of a cached user and cached token with length > 0.
   */
  public static IsUserLoggedIn(): boolean {
    let cachedUser = msalInstance.getAllAccounts()[0];
    let cachedToken = localStorage.getItem(Constants.AccessTokenKey);
    return cachedToken !== undefined && !!cachedUser && cachedToken !== null;
  }

  /**
   * Attempts to get an access token for the user. If successful,
   * sends the user to the home page again where they will now
   * be logged in.
   */
  public static async Login(): Promise<void> {
    let accessToken: string = "";
    try {
      accessToken = await AuthHelper.getAccessToken();
    } catch (err) {
      Utilities.LogError(String(err), "auth-helper.ts -> Login");
    }

    if (accessToken.length > 0) {
      // window.history.pushState('data', '', window.location.href);
      window.location.assign(window.location.href);
    }
  }

  /**
   * Clears any existing user from the cache, then requests
   * an MSAL token.
   */
  public static StartSignIn(): void {
    msalInstance["browserStorage"].clear();
    msalInstance.loginRedirect();
  }

  /**
   * Signout
   */
  public static Signout(): void {
    msalInstance["browserStorage"].clear();
    msalInstance.logoutRedirect();
  }

  public static async CheckTokenExpiry(token: any, flag: number): Promise<void> {
    if (token != null) {
      const parsejwt = JSON.parse(atob(token.split('.')[1]));
      if (parsejwt.exp * 1000 < Date.now()) {
        if (flag === 1) {
          AuthHelper.getRedirectToken();
        }
        else {
          AuthHelper.getHumanApiToken();
        }
      }
    }
  }

  public static async AcquireTokenForResource(): Promise<string> {
    let loginType = localStorage.getItem("LoginType");
    Utilities.LogInfo(
      "loginType-" + loginType,
      "auth-helper.ts -> AcquireTokenForResource"
    );
    let accessToken = "";
    if (loginType === "Force") {
      const token = await msalInstance.acquireTokenSilent({ account: msalInstance.getAllAccounts()[0], scopes: loginRequest.scopes });
      if (token.accessToken) {
        accessToken = token.accessToken;
        localStorage.setItem(Constants.AccessTokenKey, accessToken);
        Utilities.LogInfo(
          "forceToken -",
          "auth-helper.ts -> AcquireTokenForResource"
        );
      } else {
        msalInstance.loginRedirect();
        // window.history.pushState('data', '', window.location.origin);
        window.location.assign(window.location.origin);
      }
    } else {
      this.getSSOToken().then((token) => {
        accessToken = token;
      });
    }
    return accessToken;
  }
  /**
   *
   * @returns
   */

  /**
   * Called from the sign-in-end page. Checks for the presence
   * of the AD token, and notifies teams of a successful log in
   * if it is there, or notifies of failure otherwise.
   */

  public static getAccountInfo = () => {
    const accounts = msalInstance.getAllAccounts();
    return accounts[0];
  }

  public static async EndSignIn(autoClose: boolean = true, requiredReoload: boolean = false, origin: string = window.location.origin) {
    await msalInstance.handleRedirectPromise().then(async (authResponse) => {
      try {
        let accountObj;
        if (authResponse) {
          accountObj = authResponse.account;
        } else {
          accountObj = msalInstance.getAllAccounts()[0];
        }
        if (accountObj && authResponse) {
          AuthHelper.getHumanApiToken();
          let token = AuthHelper.getRedirectToken();
          if (requiredReoload) {
            // window.history.pushState('data', '', origin);
            window.location.assign(origin);
          }
          if ((window !== window.parent) && (window !== window.top)) {
          if (autoClose) authentication.notifySuccess(await token!);}
        } else if (accountObj) {
          AuthHelper.getHumanApiToken();
          AuthHelper.getRedirectToken();
        } else {
          await msalInstance.loginRedirect();
        }
      } catch (error) {
        logCustomError(error, "Error in SaveNotification");
        authentication.notifyFailure();
      }
    });
  }

  public static async getRedirectToken() {
    try {
      let token = await msalInstance.acquireTokenSilent({
        account: msalInstance.getAllAccounts()[0],
        scopes: loginRequest.scopes
      });
      localStorage.setItem(Constants.AccessTokenKey, token.accessToken);
      localStorage.setItem("LoginType", "Force");
      let ChannelType = localStorage.getItem("Channel");
      if (ChannelType === "web") {
        window.location.assign(localStorage.getItem("origin")!);
      }
      return token.accessToken;
    } catch (err) {
      await msalInstance.acquireTokenRedirect({ scopes: loginRequest.scopes });
    }
  }

  public static async getHumanApiToken() {
    try {
      let humanApiToken = await msalInstance.acquireTokenSilent({
        account: msalInstance.getAllAccounts()[0],
        scopes: loginRequest.human_api_scope
      });
      localStorage.setItem(Constants.HumanApiTokenKey, humanApiToken.accessToken);
      return humanApiToken.accessToken;
    } catch (err) {
      await msalInstance.acquireTokenRedirect({ scopes: loginRequest.human_api_scope });
    }
  }

  /**
   * Begins the login flow by opening a popup window
   * at the sign-in-start page.
   */
  private static async getAccessToken(): Promise<string> {
    localStorage.setItem("LoginType", "Force");
    return new Promise<string>((resolve, reject) => {
      authentication.authenticate({
        url: process.env.REACT_APP_SIGNIN_STARTURI!,
        width: 600,
        height: 535,
      }).then((accessToken: string | undefined) => {
        resolve(accessToken!);
        localStorage.setItem(Constants.AccessTokenKey, accessToken!);
      }).catch((err) => {
        reject(err);
        Utilities.LogError(err!, "auth-helper.ts -> getAccessToken");
      });
    })
  }


  public static async GetTeamsContext(): Promise<any> {
    let authUser: any;
    await app.initialize();
    await app.getContext().then((context: app.Context) => {
      authUser = {
        loginHint: context.user?.userPrincipalName,
        //sid: context.app.sessionId
      }
    });
    return authUser;
  }

  public static async getSSOToken(): Promise<string> {
    let ssoToken = "";
    await app.initialize();
    var authTokenRequest = {
      successCallback: function (token: string | undefined) {
        localStorage.setItem(Constants.AccessTokenKey, token!);
        ssoToken = token!;
        Utilities.LogInfo(
          "ssoToken -" + token,
          "auth-helper.ts -> getSSOToken"
        );
      },
      failureCallback: function (error: string) { Utilities.LogError(error, "auth-helper.ts -> getSSOToken"); },
      resources: [process.env.REACT_APP_RESOURCE as string]
    };
    await authentication.getAuthToken(authTokenRequest);
    return ssoToken;
  }
}
